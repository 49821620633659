import React, { useState } from "react";
import { Button, Badge } from 'react-bootstrap';
import { timeConvert, currencySign, logout } from "../../utils";
import moment from "moment";
import { isAgent, AgentKey, renderSpan, ImageWithFallback, logOut } from "../../utils"
import classnames from "classnames";
import {ReadMore} from "../../components"
import MoreFareFlightDetails from "./MoreFareFlightDetails";

export default class FlightsCards extends React.Component {
  render() {
    const {
      onwardSelectedCard,
      onChangeSelectedCard,
      index,
      item = {},
      name,
      type,
      toggleDetails,
      openFlightDetails = false,
      openShowFare = false,
      showLastListItem = true,
      returnFlightsLength,
      bookNow,
      showOfferedPrices,
      airlineSpecial,
      isShowFare = false
    } = this.props;
    let lastIndex = item && item.segments && item.segments.length;
    let Agent = isAgent();
    const maxContLen = 115; // max airline remark length
    // if(item && item.is_agent != true && isAgent()){
    //   // logOut();
    // }


    if (type === "multicity") {
      let depTime = item && item.Origin.DepTime.split("T");
      let arrTime = item && item.Destination.ArrTime.split("T");
      return (
        <React.Fragment>

          <li>
            <ImageWithFallback
              src={`https://static.udchalo.com/client_assets/img/airline_logo/${item.Airline.AirlineCode && item.Airline.AirlineCode.substring(0, 2)}.png`}
              fallbackSrc={`https://www.priceline.com/sam/air/carrier_logos/airLogo_${item.Airline.AirlineCode && item.Airline.AirlineCode.substring(0, 2)}.png`}
              alt="not available"
            />
            <p>
              <strong>{(item && item.Airline.AirlineName) || "Jet"}</strong>
            </p>
            <p>
              <span>{item && item.Airline.AirlineCode}-{item && item.Airline.FlightNumber}</span>
            </p>
          </li>

          <li>
            <h6>{depTime[1].substring(0, 5)}</h6>
            <p>{item && item.Origin.Airport.CityName} ({item && item.Origin.Airport.CityCode})</p>
            <p>{moment(depTime[0]).format("Do MMM'YY")}</p>
            <p>Terminal-{item && item.Origin.Airport.Terminal}</p>
          </li>

          <li className="duration-time">
            <p>{item && timeConvert(item.Duration)}</p>
            <div className="point-stops  custom-points">{renderSpan(item && item.segments)}</div>
          </li>

          <li>
            <h6>{arrTime[1].substring(0, 5)}</h6>
            <p>{item && item.Destination.Airport.CityName} ({item && item.Destination.Airport.CityCode})</p>
            <p>{moment(arrTime[0]).format("Do MMM'YY")}</p>
            <p>Terminal-{item && item.Destination.Airport.Terminal}</p>
          </li>
        </React.Fragment>
      )
    }
    if (type === "simple") {

      return (
        <React.Fragment>
          <li className={classnames("", { "res-d-none": returnFlightsLength })} >
            <ImageWithFallback
              src={`https://static.udchalo.com/client_assets/img/airline_logo/${item && item.AirlineCode && item.AirlineCode.substring(0, 2)}.png`} 
              fallbackSrc={`https://www.priceline.com/sam/air/carrier_logos/airLogo_${item && item.AirlineCode && item.AirlineCode.substring(0, 2)}.png`}
              alt="not available"
            />
            <p>
              <strong>{(item && item.airline) || "Jet"}</strong>
            </p>
            <p >
              <span>{item && item.flightCode}</span>
            </p>
            <p>
              <span>{item && item.segments && item.segments[0] && item.segments[0].Airline.AirlineCode} - {item && item.segments && item.segments[0] && item.segments[0].Airline.FlightNumber}</span>
            </p>
          </li>

          <li className="d-none res-d-block">
            <p>{item && item.depTime} - {item && item.arrTime}</p>
            <h5>
              {currencySign(item && item.Fare && item.Fare.Currency_code)}{" "}
              {
                // Change All Fare where it is found SHOWNFARE only in case of AGENT
                parseInt((item && item.Fare && item.Fare.PublishedFare) || 0) +
                parseInt((item && item.Fare && item.Fare.markup_price) || 0) +
                parseInt((isAgent() && item && item.Fare && item.Fare.pdc_processingfee) || 0) -
                parseInt((isAgent() && item && !item.specialfare && item.Fare && item.Fare.pdc_commission) || 0)
                // parseInt((isAgent() && item && item.Fare && item.Fare.agent_discount) || 0)
              }
            </h5>
            <p>
              <span>{item && item.segments && item.segments[0] && item.segments[0].Airline.AirlineCode} - {item && item.segments && item.segments[0] && item.segments[0].Airline.FlightNumber}</span>
            </p>
          </li>


          <li className="res-d-none">
            <h6>{(item && item.depTime) || ""}</h6>
            <p>{item && item.segments && item.segments[0] && item.segments[0].Origin && item.segments[0].Origin.Airport && item.segments[0].Origin.Airport.AirportCode}</p>
          </li>

          <li className="duration-time res-d-none">
            <p>{item && item.Duration}</p>
            <div className="point-stops">{renderSpan(item && item.segments)}</div>
            <p>
              {item && item.stops === "0" ? "Non-Stop" : `${item && item.stops} Stop`}
            </p>
          </li>

          <li className="res-d-none">
            <h6>{item && item.arrTime}</h6>
            <p>{item && item.segments && item.segments[lastIndex - 1] && item.segments[lastIndex - 1].Destination && item.segments[lastIndex - 1].Destination.Airport && item.segments[lastIndex - 1].Destination.Airport.AirportCode}</p>
          </li>

          {
            showLastListItem && (
              <li className="res-d-none">
                <h5>
                  {currencySign(item && item.Fare && item.Fare.Currency_code)}{" "}
                  {
                    // Change All Fare where it is found SHOWNFARE only in case of AGENT
                    parseInt((item && item.Fare && item.Fare.PublishedFare) || 0) +
                    parseInt((item && item.Fare && item.Fare.markup_price) || 0) +
                    parseInt((isAgent() && item && item.Fare && item.Fare.pdc_processingfee) || 0) -
                   parseInt((isAgent() && item && !item.specialfare && item.Fare && item.Fare.pdc_commission) || 0)
                    // parseInt((isAgent() && item && item.Fare && item.Fare.agent_discount) || 0)
                  }
                </h5>
              </li>
            )
          }
        </React.Fragment>
      );
    }
    if (type === "accordian") {
      let depTime = item && item.Origin.DepTime.split("T");
      let arrTime = item && item.Destination.ArrTime.split("T");
      return (
        <React.Fragment>
          <li className="res-d-none">
            <ImageWithFallback
              src={`https://static.udchalo.com/client_assets/img/airline_logo/${item.Airline.AirlineCode && item.Airline.AirlineCode.substring(0, 2)}.png`} 
              fallbackSrc={`https://www.priceline.com/sam/air/carrier_logos/airLogo_${item.Airline.AirlineCode && item.Airline.AirlineCode.substring(0, 2)}.png`}
              alt="not available"
            />
            <p>
              <strong>{(item && item.Airline.AirlineName) || "Jet"}</strong>
            </p>
            <p >
              <span>{item && item.Airline.AirlineCode}-{item && item.Airline.FlightNumber}</span>
            </p>
          </li>

          <li>
            <h6>{depTime[1].substring(0, 5)}</h6>
            <p>{item && item.Origin.Airport.CityName} ({item && item.Origin.Airport.CityCode})</p>
            <p> {moment(depTime[0]).format("Do MMM'YY")}</p>
            <p>{item && item.Origin.Airport.Terminal ? `Terminal-${item.Origin.Airport.Terminal}` : null}</p>
          </li>

          <li className="duration-time">
            <p>{item && timeConvert(item && item.Duration)}</p>
            <div className="point-stops  custom-points">{renderSpan(item && item.segments)}</div>
          </li>

          <li>
            <h6>{arrTime[1].substring(0, 5)}</h6>
            <p>{item && item.Destination.Airport.CityName} ({item && item.Destination.Airport.CityCode})</p>
            <p >{moment(arrTime[0]).format("Do MMM'YY")}</p>
            <p>{item && item.Destination.Airport.Terminal ? `Terminal-${item.Destination.Airport.Terminal}` : null}</p>
          </li>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {/* <Badge className={classnames("specialFareBadge")} variant="info">
          {item.Source == 1 ? "TBO" : "Tripshope"}
        </Badge> */}
        {
          (item.Source == 4 && !item.specialfare) ?
          <Badge className={classnames("specialFareBadge")} variant="info">
            PDC SPECIAL#
        </Badge>: null
        }
        {
          (item.specialfare || airlineSpecial || item.Defence) ?
            <Badge className={classnames("specialFareBadge", { "isSpecialFare": item.specialfare })} variant="info">
              {item.Defence ? "Defence Fare" : item.specialfare ? "PDC Special" : "Airline Special"}
            </Badge>
            : null
          // Agent && item.specialfare ? <Badge className="specialFareBadge" variant="info">PDC special</Badge> : airlineSpecial && <Badge className="specialFareBadge" variant="info">AirlineSpecial</Badge>
        }
        {
          (item.ResultFareType && item.ResultFareType =="StudentFare") ?
          <Badge className={classnames("specialFareBadge")}  variant="info">
              Student Fare
            </Badge>
            : null
        }
      
             
          <span className="refund-badge" >
            {(item.IsRefundable) ? "Partially Refundable " : "Non-Refundable "}
          </span>
          
        

        <li>
          <ImageWithFallback
            src={`https://static.udchalo.com/client_assets/img/airline_logo/${(item.segments && item.segments[0] && item.segments[0].Airline && item.segments[0].Airline.AirlineCode) ? item.segments[0].Airline.AirlineCode.substring(0, 2) : item.AirlineCode.substring(0, 2)}.png`} 
            fallbackSrc={`https://www.priceline.com/sam/air/carrier_logos/airLogo_${(item.segments && item.segments[0] && item.segments[0].Airline && item.segments[0].Airline.AirlineCode) ? item.segments[0].Airline.AirlineCode.substring(0, 2) : item.AirlineCode.substring(0, 2)}.png`}
            alt="not available"
          />
          <p>
            <strong>{(item && item.airline) || ""}</strong>
            {/* <div className="abd" >All NipponNipponNipponNippon Airways  NipponNipponNipponNippon NipponNipponNipponNipponCo</div> */}
          </p>
          <p className='res-d-none'>
            <span>{item.segments && item.segments[0] && item.segments[0].Airline.AirlineCode} - {item.segments && item.segments[0] && item.segments[0].Airline.FlightNumber}</span>
          </p>
          { (item.segments[0].numSeatLeft) ?
        
          <p style={{color: "#ed3237"}}><strong>{item.segments[0].numSeatLeft} seat(s) left</strong></p>
        : "" 
        }
        </li>
       

        <li>
          <h5>{item.segments && item.segments[0] && item.segments[0].Origin && item.segments[0].Origin.Airport && item.segments[0].Origin.Airport.AirportCode}</h5>
          <h6 className='mt-1'>{item && item.depTime}</h6>
          <p className='res-d-none'>{item.segments && item.segments[0] && item.segments[0].Origin && item.segments[0].Origin.Airport && item.segments[0].Origin.Airport.CityName}</p>
        </li>
       

        <li className="duration-time">
          <p>{item && item.duration}</p>
          <div className="point-stops">{renderSpan(item && item.segments)}</div>
          <p>
            {item && item.stops === "0" ? "Non-Stop" : `${item && item.stops} Stop`}
          </p>
        </li>

        <li>
          <h5>{item.segments && item.segments[lastIndex - 1] && item.segments[lastIndex - 1].Origin && item.segments[lastIndex - 1].Destination.Airport && item.segments[lastIndex - 1].Destination.Airport.AirportCode}</h5>
          <h6 className='mt-1'>{item && item.arrTime}</h6>
          <p className='res-d-none'>{item.segments && item.segments[lastIndex - 1] && item.segments[lastIndex - 1].Origin && item.segments[lastIndex - 1].Destination.Airport && item.segments[lastIndex - 1].Destination.Airport.CityName}</p>
        </li>
        {
          Agent && !returnFlightsLength ? <React.Fragment>
            <li>
              <div>Published
                <h6>
                  {currencySign(item && item.Fare && item.Fare.Currency_code)}{" "}
                  {
                    // Change All Fare where it is found SHOWNFARE only in case of AGENT
                    parseInt((item && item.Fare && item.Fare.PublishedFare) || 0) +
                    parseInt((item && item.Fare && item.Fare.markup_price) || 0) +
                    parseInt((item && item.Fare && item.Fare.pdc_processingfee) || 0) -
                     parseInt((isAgent() && item && !item.specialfare && item.Fare && item.Fare.pdc_commission) || 0) 
                    // parseInt((item && item.Fare && item.Fare.agent_discount) || 0) +
                    // parseInt((item && item.Fare && item.Fare.agent_discount_tds) || 0)


                  }
                </h6>
              </div>
            </li>
            {
              !showOfferedPrices ? <li>
                <div>Offered
                  <h6>
                    {currencySign(item && item.Fare && item.Fare.Currency_code)}{" "}
                    {
                      parseInt(item && item.Fare && item.Fare[AgentKey()]) +
                      parseInt(item && item.Fare && item.Fare.pdc_processingfee)
                    }
                  </h6>
                </div>
              </li> : null
            }
          </React.Fragment> : null
        }

        <li className={(Agent && !returnFlightsLength) ? "res-d-none " : ""}>
          {
            !Agent && !returnFlightsLength ? <h5>
              {currencySign(item && item.Fare && item.Fare.Currency_code)}{" "}
              {parseInt(item && item.Fare && item.Fare[AgentKey()])}
            </h5> : null
          }
          {
            !Agent && returnFlightsLength ?
              <h5>
                {currencySign(item && item.Fare && item.Fare.Currency_code)}{" "}
                {parseInt(item && item.Fare && item.Fare[AgentKey()])}
              </h5> : null
          }
          {
            Agent && returnFlightsLength ? <div className='price-diff-box'>
              <div className='prices-diff-label'>Published
                <h6>
                  <strong>
                    {currencySign(item && item.Fare && item.Fare.Currency_code)}{" "}
                    {
                      // Change All Fare where it is found SHOWNFARE only in case of AGENT
                      parseInt((item && item.Fare && item.Fare.PublishedFare) || 0) +
                      parseInt((item && item.Fare && item.Fare.markup_price) || 0) +
                      parseInt((item && item.Fare && item.Fare.pdc_processingfee) || 0) -
                      parseInt((isAgent() && item && !item.specialfare && item.Fare && item.Fare.pdc_commission) || 0)
                      // parseInt((item && item.Fare && item.Fare.agent_discount) || 0)

                    }
                  </strong>
                </h6>
              </div>

              {
                !showOfferedPrices ? <div className='prices-diff-label'>Offered
                  <h6>
                    <strong>
                      {currencySign(item && item.Fare && item.Fare.Currency_code)}{" "}
                      {
                        parseInt(item && item.Fare && item.Fare[AgentKey()]) +
                        parseInt(item && item.Fare && item.Fare.pdc_processingfee)
                      }
                    </strong>
                  </h6>
                </div> : null
              }
            </div> : null
          }

          {
            !returnFlightsLength ?
              <div className='book-now-singleView res-d-none'>
                {/* for one way web*/}
                <Button className="btn-green" onClick={() => bookNow(item.id)}>Book Now</Button>
                {
                  type === "duplicateFlightNo" ?
                    <p className="more-fare one-way-fare " onClick={(e) => toggleDetails("openShowFare", e)}>
                      {openShowFare ? "Hide Fares -" : "More Fares +"}
                    </p> : null
                }
              </div>
              : null

          }

        </li>

        {
          !returnFlightsLength ?
            <React.Fragment>
              {/* for one way responsive */}
              <li className="checkout-label d-none res-d-block">
                <label className="customradio">
                  <input
                    type="checkbox"
                    name={name}
                    value={onwardSelectedCard}
                    onChange={text => onChangeSelectedCard(name, item.id)}
                    checked={onwardSelectedCard === item.id}
                    inline="true"
                  />
                  <span className="checkmark" />
                </label>

              </li>
              {
                type === "duplicateFlightNo" ?
                  <div className="d-none res-d-block">
                    <p className="more-fare" onClick={(e) => toggleDetails("openShowFare", e)}>
                      {openShowFare ? "Hide Fares -" : "More Fares +"}
                    </p>
                  </div> : null
              }
            </React.Fragment>

            : null
        }


        {
          returnFlightsLength ?
            <React.Fragment>
              <li className="checkout-label">
                <label className="customradio">
                  <input
                    type="checkbox"
                    name={name}
                    value={onwardSelectedCard}
                    onChange={text => onChangeSelectedCard(name, item.id)}
                    checked={onwardSelectedCard === item.id}
                    inline="true"
                  />
                  <span className="checkmark" />
                </label>

              </li>
              {
                type === "duplicateFlightNo" ?
                  <p className=" show-fares-btn more-fare" onClick={(e) => toggleDetails("openShowFare", e)}>
                    {openShowFare ? "Hide Fares -" : "More Fares +"}
                  </p> : null
              }
            </React.Fragment>
            : null
        }


        {
          !item.specialfare ? <p className="flights-airline-remarks"><ReadMore maxContLen={maxContLen} description={item.AirlineRemark} index={index}  /></p> : null
        }



        {
             (item.specialfare && item.special_non_refundable) ?
          <p className="flights-airline-remarks">
            {(item.special_non_refundable == "0") ? "Partially Refundable " : (item.special_non_refundable == "1") ? "Non-Refundable " : (item.special_non_refundable == "2") ? "Non-Refundable | Non-Changeable " : (item.special_non_refundable == "3") ? "Non-Refundable | Non-Changeable | Non-Cancellable " : ""}
            <ReadMore maxContLen={90} description={` fare,confirmation will be given only in office hours(10am to 8pm). Please call helpdesk for reconfirmation 0141-4101991`} index={index}  />
          </p>
          : null
        }

        {
          isShowFare ? null :
            <span
              className={classnames("flight-detail active cursor-pointer", { "specialFareAgent": returnFlightsLength && Agent }, { "singleView": !returnFlightsLength }, { "guestUser": !Agent })}
              // className={openFlightDetails ? (!returnFlightsLength ? "flight-detail active singleView cursor-pointer d-block" : "flight-detail active cursor-pointer specialFareAgent") : (!returnFlightsLength ? "flight-detail active singleView cursor-pointer d-block" : "flight-detail active cursor-pointer specialzAgent")}
              onClick={(e) => toggleDetails("openFlightDetails", e)}
            >
              Flight Details{" "}
              <i className={openFlightDetails ? `fas fa-caret-up` : `fas fa-caret-down`} />
            </span>
        }

      </React.Fragment>
    );
  }
}