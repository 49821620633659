import types from "../types";
import {
  getFaresInfoAPI,
  setFareQuotaAPI,
  setFlightServicesAPI,
  flightBookingAPI,
  flightTicketsBookingAPI,
  flightPaymentAPI,
  flightBookAfterPaymentAPI,
  paymentSuccessOrderGenerationAPI,
  checkTBOBalanceApi,
  inSufficientFundsApi,
  getOfflinePaymentAmountAPI,
  getOfflinePaymentPortalAPI,
  requestPaymentSuccessAPI
} from "../api/faresinfo";


//-----------------Flight Fare---------------------
export const getFaresInfo = (data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({
      type: types.GET_FARES_DATEWISE
    });
    getFaresInfoAPI(data)
      .then(res => {
        dispatch({
          type: types.GET_FARES_DATEWISE_SUCCESS,
          payload: res
        });
        return resolve(res);
      })
      .catch(err => {
        dispatch({
          type: types.GET_FARES_DATEWISE_FAILED
        })
        return reject(err);
      });
  });



export const setFareQuota = (data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({
      type: types.SET_FARE_QUOTA
    });
    setFareQuotaAPI(data)
      .then(res => {
        localStorage.setItem(`bookingkey`, res.info?.bookingkey);
        dispatch({
          type: types.SET_FARE_QUOTA_SUCCESS,
          payload: res
        });
        return resolve(res);
      })
      .catch(err => {
        dispatch({
          type: types.SET_FARE_QUOTA_FAILED
        })
        return reject(err);
      });
  })


//this action is used to get details like baggage and meals details

export const setFlightServices = (data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({
      type: types.SET_FLIGHT_SERVICE
    });
    setFlightServicesAPI(data)
      .then(res => {
        dispatch({
          type: types.SET_FLIGHT_SERVICE_SUCCESS,
          payload: res
        });
        return resolve(res);
      })
      .catch(err => {
        dispatch({
          type: types.SET_FLIGHT_SERVICE_FAILED
        })
        return reject(err);
      });
  })

export const flightBooking = (data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({
      type: types.SET_BOOKING
    });
    flightBookingAPI(data)
      .then(res => {
        dispatch({
          type: types.SET_BOOKING_SUCCESS,
          payload: res
        });
        return resolve(res);
      })
      .catch(err => {
        dispatch({
          type: types.SET_BOOKING_FAILED
        })
        return reject(err);
      });
  })

export const flightTicketsBooking = (data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({
      type: types.SET_TICKET_DETAILS
    });
    flightTicketsBookingAPI(data)
      .then(res => {
        dispatch({
          type: types.SET_TICKET_DETAILS_SUCCESS,
          payload: res
        });
        return resolve(res);
      })
      .catch(err => {
        dispatch({
          type: types.SET_TICKET_DETAILS_FAILED
        })
        return reject(err);
      });
  });


export const flightPayment = (data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({
      type: types.MAKE_PAYMENT
    });
    flightPaymentAPI(data)
      .then(res => {
        dispatch({
          type: types.MAKE_PAYMENT_SUCCESS,
          payload: res
        });
        return resolve(res);
      })
      .catch(err => {
        dispatch({
          type: types.MAKE_PAYMENT_FAILED
        })
        return reject(err);
      });
  })


//action used for the flight ticket book after payment successfull
export const flightTicketsBookAfterPayment = (data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({
      type: types.TICKET_BOOKING_AFTER_PAYMENT
    });
    flightBookAfterPaymentAPI(data)
      .then(res => {
        dispatch({
          type: types.TICKET_BOOKING_AFTER_PAYMENT_SUCCESS,
          payload: res
        });
        return resolve(res);
      })
      .catch(err => {
        dispatch({
          type: types.TICKET_BOOKING_AFTER_PAYMENT_FAILED,
          error: err
        })
        return reject(err);
      });
  });

export const paymentSuccessOrderGeneration = (data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({
      type: types.PAYMENT_SUCCESS_ORDER
    });
    paymentSuccessOrderGenerationAPI(data)
      .then(res => {
        dispatch({
          type: types.PAYMENT_SUCCESS_ORDER_SUCCESS,
          payload: res
        });
        return resolve(res);
      })
      .catch(err => {
        dispatch({
          type: types.PAYMENT_SUCCESS_ORDER_FAILED,
          error: err
        })
        return reject(err);
      });
  });
//----------------------------------------------------------

//-----------------TBO Balance Fare-------------------------

export const checkTBOBalance = (data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({
      type: types.CHECK_TBO_BALANCE
    });
    checkTBOBalanceApi(data)
      .then(res => {
        dispatch({
          type: types.CHECK_TBO_BALANCE_SUCCESS,
          payload: res
        });
        return resolve(res);
      })
      .catch(err => {
        dispatch({
          type: types.CHECK_TBO_BALANCE_FAILED,
          error: err
        })
        return reject(err);
      });
  });


export const inSufficientFunds = (data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({
      type: types.INSUFFICIENT_FUNDS
    });
    inSufficientFundsApi(data)
      .then(res => {
        dispatch({
          type: types.INSUFFICIENT_FUNDS_SUCCESS,
          payload: res
        });
        return resolve(res);
      })
      .catch(err => {
        dispatch({
          type: types.INSUFFICIENT_FUNDS_FAILED,
          error: err
        })
        return reject(err);
      });
  });

//----------------------------------------------------------

//------------------Offline Payment Request-----------------
export const getOfflinePaymentAmount = (data) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: types.GET_OFFLINE_PAYMENT_AMOUNT
    });
    getOfflinePaymentAmountAPI(data)
      .then(res => {
        dispatch({
          type: types.GET_OFFLINE_PAYMENT_AMOUNT_SUCCESS,
          payload: res
        });
        return resolve(res);
      })
      .catch(err => {
        dispatch({
          type: types.GET_OFFLINE_PAYMENT_AMOUNT_FAILED,
          error: err
        })
        return reject(err);
      });
  });
}

export const getOfflinePaymentPortal = (data) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: types.GET_OFFLINE_PAYMENT_PORTAL
    });
    getOfflinePaymentPortalAPI(data)
      .then(res => {
        dispatch({
          type: types.GET_OFFLINE_PAYMENT_PORTAL_SUCCESS,
          payload: res
        });
        return resolve(res);
      })
      .catch(err => {
        dispatch({
          type: types.GET_OFFLINE_PAYMENT_PORTAL_FAILED,
          error: err
        })
        return reject(err);
      });
  });
}

export const requestPaymentSuccess = (data) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: types.REQUEST_OFFLINE_PAYMENT
    });
    requestPaymentSuccessAPI(data)
      .then(res => {
        dispatch({
          type: types.REQUEST_OFFLINE_PAYMENT_SUCCESS,
          payload: res
        });
        return resolve(res);
      })
      .catch(err => {
        dispatch({
          type: types.REQUEST_OFFLINE_PAYMENT_FAILED,
          error: err
        })
        return reject(err);
      });
  });
}

//---------------------------------------------------------