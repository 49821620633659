import React from 'react';
import { Col } from "react-bootstrap";
import { timeConvert, timeDifference, ImageWithFallback } from "../../utils"
import moment from "moment"



const CardComponent = props => {
    const {
        data,
        index,
        layoverTime,
        hasSameLayoverPlane = false,
        item
    } = props;


    let depTime = data && data.Origin.DepTime.split("T");
    let arrTime = data && data.Destination.ArrTime.split("T");
    let timeDiff = 0;

    localStorage.setItem(`arrDep_${index}`, JSON.stringify({arr : arrTime, dep : depTime}));
 
    // If layover time(groundTime) is zero then get the time by date difference
    if(index && !layoverTime){
        let prevArrDep = JSON.parse(localStorage.getItem(`arrDep_${index-1}`)); 
        let currArrDep = JSON.parse(localStorage.getItem(`arrDep_${index}`));
        timeDiff = timeDifference((prevArrDep.arr).join(' '), (currArrDep.dep).join(' '))
    }

    return (
        <React.Fragment key={index}>
            {
                index > 0 ? <Col sm={12} md={12} className="text-center review-layover ">
                    <div className="layover">
                        <p>
                            <strong>{
                                hasSameLayoverPlane ? null : "Change of Planes"
                            }
                            </strong>
                            {index > 0 ? timeConvert(layoverTime ? layoverTime : timeDiff) : null}
                            {" "} layover in {" "}
                            <strong>{data.Origin.Airport.CityName}</strong>
                        </p>
                    </div>
                </Col> : null
            }

            <Col sm={2} md={2} className="airline-flight-details">
                <ImageWithFallback
                src={`https://static.udchalo.com/client_assets/img/airline_logo/${data.Airline.AirlineCode && data.Airline.AirlineCode.substring(0, 2)}.png`}
                fallbackSrc={`https://www.priceline.com/sam/air/carrier_logos/airLogo_${data.Airline.AirlineCode && data.Airline.AirlineCode.substring(0, 2)}.png`}
                alt="not available"
                />
                <h6>{data && data.Airline.AirlineName} </h6>
                <p>{data && data.Airline.AirlineCode}-{data && data.Airline.FlightNumber}</p>
            </Col>
            <Col sm={3} md={3} className="airline-flight-details from-details">
                <h6>{data && data.Origin.Airport.CityName}, {data.Origin.Airport.CountryCode} </h6>
                <h6>{depTime[1].substring(0, 5)}</h6>
                <span>{data && moment(data.Origin.DepTime).format("Do MMM'YY HH:mm")}</span>
                <p><small> {data && data.Origin.Airport.AirportName}{data && data.Origin.Airport.Terminal ? `, T-${data && data.Origin.Airport.Terminal}` : ""}</small></p>
            </Col>
            <Col sm={4} md={4} className="col-details airline-flight-details duration-details p-0">
                <ul>
                    <li className="">{timeConvert(data && data.Duration)}</li>
                    <li className="point-stops"></li>
                   {  (item && item.specialfare && item.special_baggage) ?
                   <li>Baggage: {item.special_baggage}</li>
                   :
                    <li> Baggage: {data.Baggage} </li>
            }
                </ul>
            </Col>
            <Col sm={3} md={3} className="airline-flight-details to-details">
                <h6>{data && data.Destination.Airport.CityName}, {data.Destination.Airport.CountryCode} </h6>
                <h6>{arrTime[1].substring(0, 5)}</h6>
                <span>{data && moment(data.Destination.ArrTime).format("Do MMM'YY HH:mm")}</span>
                <p><small> {data && data.Destination.Airport.AirportName}{data && data.Destination.Airport.Terminal ? `, T-${data && data.Destination.Airport.Terminal}` : ""}</small></p>
            </Col>

        </React.Fragment>
    );
};

export default CardComponent